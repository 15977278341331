var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mf-loading-dialog',{attrs:{"loading":_vm.$apollo.loading || _vm.loading}},[_c('v-container',{staticClass:"pb-2 mt-n4",attrs:{"fluid":""}},[_c('v-layout',[_c('p',{staticClass:"ml-1 page-subtitle"},[_vm._v("Cadastrar venda")]),_c('v-spacer')],1),_c('v-card-actions',{staticClass:"px-0 mt-2 mb-1"},[_c('v-btn',{attrs:{"outlined":"","width":"300","color":"error"},on:{"click":function($event){return _vm.$router.push(("/retailers/" + _vm.clientId + "/edit/acoes"))}}},[_vm._v("Cancelar")]),_c('v-spacer'),_c('v-btn',{attrs:{"width":"300","color":"primary","outlined":""},on:{"click":function($event){_vm.addProductModal = true}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-plus")]),_vm._v(" Inserir produto")],1),_c('v-btn',{attrs:{"width":"300","color":"primary","disabled":!_vm.products.length},on:{"click":function($event){_vm.confirm_sale = true}}},[_c('v-icon',{staticClass:"mr-2 mb-1"},[_vm._v("mdi-content-save-check")]),_vm._v(" Concluir venda")],1)],1),_c('v-card',{staticClass:"pa-8"},[_c('v-card-actions',[_c('v-spacer'),_c('h2',{staticClass:"mx-4 mb-2"},[_vm._v("Total: "+_vm._s(_vm.total))])],1),_c('v-data-table',{attrs:{"footer-props":{ itemsPerPageOptions: [5, 10, 25, 50] },"headers":_vm.headers,"items":_vm.products},scopedSlots:_vm._u([{key:"item.unit_value",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.type === 'sms' ? _vm.parseCurrencySms(item.unit_value) : _vm.parseCurrencyDefault(item.unit_value))+" ")])],1)]}},{key:"item.charge_type",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.type === 'sms' || item.type === 'mercalider' ? (item.charge_type === 'recurring' ? 'Recorrente' : 'Única') : '-')+" ")])],1)]}},{key:"item.is_accumulative",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.type === 'sms' ? (item.is_accumulative ? 'Sim' : 'Não') : '-')+" ")])],1)]}},{key:"item.has_order",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.type === 'mercashop' ? (item.has_order ? 'Sim' : 'Não') : '-')+" ")])],1)]}},{key:"item.implantation_value",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.getProductFromType(item.type).hasSetupCost ? _vm.parseCurrencyDefault(item.implantation_value) : '-')+" ")])],1)]}},{key:"item.plan",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.type === 'mercalider' ? item.plan : '-')+" ")])],1)]}},{key:"item.total_value",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.parseCurrencyDefault(item.total_value))+" ")])],1)]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.popProduct(item)}}},[_vm._v("Remover")])],1)],1)]}}],null,true)})],1)],1),_c('add-product-dialog',{attrs:{"dialog":_vm.addProductModal,"client-units":_vm.clientUnits,"products":_vm.products,"show-units-input":"","availableProducts":_vm.availableProducts},on:{"close":_vm.close}}),_c('dialog-confirm-sale',{attrs:{"is-sale":"","validate-contract-link":true,"disable-button":_vm.disable_button,"has-billing-date":true},on:{"confirm-action":_vm.saveSale},model:{value:(_vm.confirm_sale),callback:function ($$v) {_vm.confirm_sale=$$v},expression:"confirm_sale"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }